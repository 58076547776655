<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout row wrap justify-start align-left my-1>
		<TasksManagerTaskIcon shrink :task="task" />
		<v-layout id="layoutTaskListDetails" row wrap>
			<v-flex :class="computeTaskTitleSize()" shrink>
				<v-layout v-if="!isNew" fill-height column justify-center align-start>
					<v-flex text-truncate v-html="taskTitleHtml" />
					<v-flex v-if="isUserAccountant && isMainTask" text-truncate caption v-html="taskSubTitle" />
				</v-layout>
				<v-layout v-else fill-height row justify-start align-center>
					<v-tooltip bottom nudge-left>
						<template v-slot:activator="{ on }">
							<v-flex v-if="noAction" text-truncate v-on="on" v-html="taskTitleHtml" />
						</template>
						<v-flex v-text="task.description" />
					</v-tooltip>
					<v-text-field v-if="!noAction" v-model="task.title" :maxlength="191" text-truncate />
				</v-layout>
			</v-flex>
			<v-flex v-if="!isNew" xs1 justify-start align-center shrink>
				<TasksManagerTaskDocumentsIcon :task="task" />
				<TasksManagerTaskNotesIcon :task="task" />
			</v-flex>
			<v-flex :class="computeTaskDateSize()" :offset-xs1="noAction" shrink>
				<v-layout v-if="isNew" row wrap justify-start align-center fill-height @keydown.enter="saveTask">
					<v-menu
						v-model="dueDateMenu"
						:close-on-content-click="false"
						:nudge-right="40"
						lazy
						transition="scale-transition"
						offset-y
						full-width
						min-width="290px"
					>
						<template v-slot:activator="{ on }">
							<v-text-field :value="task.due_date" :label="$t('due_date')" prepend-icon="event" readonly type="date" v-on="on"></v-text-field>
						</template>
						<v-date-picker
							v-model="task.due_date"
							:first-day-of-the-week="$firstDayOfTheWeek()"
							:locale="$i18n.locale"
							:min="today"
							@input="dueDateMenu = false"
						></v-date-picker>
					</v-menu>
				</v-layout>
				<v-layout v-else row wrap justify-start align-center fill-height>
					<v-tooltip v-if="taskDelay <= 1" bottom>
						<template v-slot:activator="{ on }">
							<v-flex shrink row wrap justify-center align-center v-on="on">
								<span v-if="!isMinimized" v-text="taskDueDate" />
								<v-icon :color="taskDelay < 0 ? 'error' : 'warning'" small class="ml-1">fas fa-exclamation-triangle</v-icon>
							</v-flex>
						</template>
						<span v-text="taskDelay < 0 ? $t('client_workspace.tasks.due_date.late', { dueDate: taskDueDate }) : $t('client_workspace.tasks.due_date.lastday', { dueDate: taskDueDate })" />
					</v-tooltip>
					<v-flex v-else shrink row wrap justify-center align-center>
						<span v-if="!isMinimized" v-text="taskDueDate" />
					</v-flex>
				</v-layout>
			</v-flex>
			<TasksManagerTaskCounters v-if="!isNew && hasChildTasks" :task="task" :minimized="isMinimized" align-center justify-end mr-1 />
			<v-flex v-if="isNew && !noAction" xs1 justify-center align-center>
				<v-layout shrink justify-center align-center fill-height>
					<v-btn flat icon small color="primary" @click="saveTask">
						<v-icon>save</v-icon>
					</v-btn>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTask',
	components: {
		TasksManagerTaskIcon: () => ({
			component: import('@/components/TasksManager/Task/TasksManagerTaskIcon')
		}),
		TasksManagerTaskDocumentsIcon: () => ({
			component: import('@/components/TasksManager/Task/TasksManagerTaskDocumentsIcon')
		}),
		TasksManagerTaskNotesIcon: () => ({
			component: import('@/components/TasksManager/Task/TasksManagerTaskNotesIcon')
		}),
		TasksManagerTaskCounters: () => ({
			component: import('@/components/TasksManager/Task/TasksManagerTaskCounters')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		},
		minimized: {
			required: false,
			type: Boolean,
			default: null
		},
		noAction: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			today: new Date().toISOString(),
			tree: [],
			dueDateMenu: false
		}
	},
	computed: {
		...mapState({
			companies: state => state.tasks.companies,
			filters: state => state.tasks.filters,
			isUserAccountant: state => state.auth.isAccountant
		}),
		task: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		isMinimized: function () {
			let result
			if (this.minimized !== null) {
				result = this.minimized
			} else {
				result = this.$vuetify.breakpoint.smAndDown
			}
			return result
		},
		taskDelay: function () {
			let result = 999
			if (this.task.due_date && !this.task.status?.is_done) {
				result = (new Date(this.task.due_date.replace(/-/g, '/')).getTime() - new Date().getTime()) / (1000 * 3600 * 24)
			}
			return result
		},
		isNew: function () {
			return this.task.id ? false : true
		},
		isMainTask: function () {
			return !this.task.isChild
		},
		hasChildTasks: function () {
			return this.task.children && this.task.children.length > 0
		},
		taskTitleHtml: function () {
			let title = this.task.title
			if (this.$te(title)) {
				title = this.$tc(title, 2)
			}
			return this.$highlightMatching(title, this.filters.search)
		},
		taskSubTitle: function () {
			let result = ''
			if (!this.task.vendor) {
				result = this.$t('client_workspace.tasks.internalType')
			} else {
				let foundCompany = this.companies.find(company => company.id == this.task.vendor.id)
				result = foundCompany ? foundCompany.fullname : ''
			}
			result = this.$highlightMatching(result, this.filters.search)
			return result
		},
		taskDueDate: function () {
			let result = ''
			if (this.task.due_date) {
				result = this.$d(new Date(this.task.due_date.replace(/-/g, '/')), 'tiny')
			}
			return result
		}
	},
	methods: {
		saveTask: function () {
			this.service.createTask(this.task)
		},
		computeTaskTitleSize: function () {
			let size
			if (this.isNew) {
				size = 'xs6 md7 lg6 xl6'
			} else if (!this.isMainTask) {
				size = 'xs10 md8 lg7 xl8'
			} else if (this.isMinimized) {
				size = 'xs8'
			} else {
				size = 'xs7 md3 lg5 xl7'
			}
			return size
		},
		computeTaskDateSize: function () {
			let size
			if (this.isNew) {
				size = 'xs5 md4 lg5'
			} else if (!this.isMainTask) {
				size = 'xs1 md3 lg4 xl3'
			} else if (this.isMinimized) {
				size = 'xs1'
			} else {
				size = 'xs1 md3 lg2 xl1'
			}
			return size
		}
	}
}
</script>

<style scoped>
#layoutTaskListDetails {
	max-width: calc(100% - 32px);
}
</style>
